import * as React from 'react';

import { quoteNormal, quoteFlipped } from './quote.module.css';

const Quote = ({
  style,
  attribution,
  photo = null,
  children,
  flipped = false,
  quoteFontSize = '2em',
  color = 'var(--color-accent)',
}) => {
  return (
    <figure
      className={flipped ? quoteFlipped : quoteNormal}
      style={{ ...style, '--color-quote-accent': color }}>
      {photo}
      <blockquote style={{ fontSize: quoteFontSize }}>
        <p>{children}</p>
      </blockquote>
      <figcaption>{attribution}</figcaption>
    </figure>
  );
};

export default Quote;
