import * as React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { articlesList } from './articles-list.module.css';

const ArticlesList = ({
  articles,
  HeadingElement = 'h2',
  descriptionField = 'description',
}) => {
  return (
    <ul className={articlesList}>
      {articles.map(({ node: article }) => {
        let featuredImage;
        const featuredImageAltText = `${article.frontmatter.title} featured image`;
        const isUnsupportedImageType =
          !article.frontmatter?.coverImage?.childImageSharp &&
          article?.frontmatter?.coverImage?.publicURL;
        if (isUnsupportedImageType) {
          featuredImage = (
            <img
              src={article.frontmatter.coverImage.publicURL}
              alt={featuredImageAltText}
            />
          );
        } else {
          featuredImage = (
            <GatsbyImage
              image={getImage(article.frontmatter.coverImage)}
              alt={featuredImageAltText}
            />
          );
        }
        return (
          <li>
            <Link to={`/articles/${article.slug}`}>
              <HeadingElement>{article.frontmatter.title}</HeadingElement>
              {featuredImage}
            </Link>
            <p>{article.frontmatter[descriptionField] || article.excerpt}</p>
            <Link
              to={`/articles/${article.slug}`}
              className="button"
              style={{ display: 'inline-block', marginTop: '0.5em' }}>
              Read more&hellip;
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default ArticlesList;
