import * as React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { Seo } from '../components/seo';
import Article from '../components/article';
import ArticlesList from '../components/articles-list';
import { textContainer } from '../components/text-container.module.css';
import Quote from '../components/quote';

const HomePage = ({ data, location }) => {
  const pageTitle = 'Start your career as a web developer';
  const brandonHaynes = (
    <StaticImage
      src="../images/brandon-haynes.jpg"
      alt="Brandon Haynes photo"
    />
  );
  return (
    <Layout pageTitle={pageTitle}>
      <Seo
        pageTitle={pageTitle}
        description={`I want to help you leave your 💩 job to become a web developer.`}
        pathname={location.pathname}
      />
      <Article style={{ paddingBottom: '0' }}>
        <h1
          className="fancy"
          style={{
            textAlign: 'center',
            hyphens: 'none',
          }}>
          Leave your{' '}
          <span
            style={{
              fontSize: '2em',
              lineHeight: '1',
              display: 'block',
              textShadow:
                'var(--color-primary-tint1) 0.5rem 0.25rem, var(--color-accent) 1rem 0.5rem',
            }}>
            💩
          </span>{' '}
          job behind
        </h1>
        <p>
          Maybe your job sucks like mine did. Maybe you wake up every morning
          looking for an excuse to stay home like I did. Maybe you're learning
          to write code to escape like I did. Maybe you can't get anyone to hire
          you like I couldn't.
        </p>
        <p>
          If that sounds like you, I created this web site so you can break into
          a tough industry&hellip; like I did! When you started learning, you
          probably heard how many job openings there are for web developers.
          It's so true! What they don't tell you though is that those openings
          are for senior-level developers, not fresh-outta-bootcamp newbies like
          us.
        </p>
        <h2>I've been there, and I stumbled into a way to make it work.</h2>
        <p>
          When you find yourself hitting that wall, check out some of the
          articles and resources here. Everyone is different, and my method
          won't work for everyone, but, you know what else isn't working?
          Shotgunning applications to every opening under the sun and hoping for
          the best.
        </p>
        <h2 className="fancy">Why Web Development?</h2>
        <p>
          Learning web development is a great way to get out of
          your&nbsp;💩&nbsp;job and{' '}
          <strong>start building a better life for yourself</strong>. Here’s
          why:
        </p>
        <ul>
          <li>
            <a href="https://insights.stackoverflow.com/survey/2020#work-how-do-developers-feel-about-their-jobs">
              <strong>63% of developers</strong> reported they were satisfied
              with their careers
            </a>
          </li>
          <li>
            <a href="https://www.bls.gov/ooh/computer-and-information-technology/web-developers.htm#tab-5">
              Web developer salaries are <strong>70% more</strong> than the
              overall average
            </a>
          </li>
          <li>
            The field already has tons of positions waiting to be filled and
            that number is{' '}
            <a href="https://www.bls.gov/ooh/computer-and-information-technology/web-developers.htm#tab-6">
              set to <strong>grow 30%</strong> by 2031
            </a>{' '}
            — <strong>six times</strong> the growth of the average
          </li>
        </ul>
        <Quote
          photo={brandonHaynes}
          attribution="Brandon Haynes"
          quoteFontSize="1.75em"
          style={{ marginTop: '4em' }}>
          Devon got me to step outside my comfort zone and pushed me to do the
          hard things I didn’t want to do. It wasn’t easy and I’m nowhere near
          done, but I did just pick up my first paying work. It finally feels
          like I could break out of my rut and build the life I’ve always
          wanted.
        </Quote>
        <div
          style={{
            background: 'hsla(325.6,100%,50%,0.1)',
            padding: '1em',
            marginTop: '4em',
            border: '5px dashed var(--color-primary)',
            marginBottom: '4em',
          }}>
          <h2 className="fancy" style={{ fontWeight: '900' }}>
            {data.frontPageArticle.frontmatter.title}
          </h2>
          <MDXRenderer>{data.frontPageArticle.body}</MDXRenderer>
        </div>
        <p>
          That's a little about what I learned that got me started in my new
          career. If you're looking for a new approach, here are some resources
          to get you started.{' '}
        </p>
      </Article>
      <div className={textContainer} style={{ marginTop: '-1.4em' }}>
        <h2 className="fancy" style={{ marginBottom: '0' }}>
          Start Here!
        </h2>
        <ArticlesList
          articles={data.startHereArticles.edges}
          HeadingElement="h3"
          descriptionField="startHereDescription"
        />
        <a href="/articles/" className="button" style={{ marginTop: '1em' }}>
          See more articles
        </a>
      </div>
    </Layout>
  );
};
export default HomePage;

export const query = graphql`
  query HomePageQuery {
    startHereArticles: allMdx(
      filter: { frontmatter: { tags: { eq: "start here" } } }
      sort: { fields: [frontmatter___startHereOrder], order: ASC }
    ) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            coverImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              publicURL
            }
            title
            tags
            date
            startHereDescription
            startHereOrder
          }
        }
      }
    }
    frontPageArticle: mdx(
      frontmatter: {
        title: {
          eq: "8 Things I Learned to Get From Walmart Cashier to 6-Figure Web Developer"
        }
      }
    ) {
      frontmatter {
        title
      }
      body
    }
  }
`;
